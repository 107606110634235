import { graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import { getTheme } from "gatsby-interface"
import Layout from "../../layouts"
import Container from "../../components/container"
import SEO from "../../components/seo"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Hero from "../../components/hero"
import HeroContainer from "../../components/containers/hero"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"

import { getLayout } from "../../utils/layout"

import { textStyles } from "./styles"
import RotatingSquare from "./geometries/RotatingSquare"
import Circle from "./geometries/Circle"
import SamePageLink from "./SamePageLink"
import MiniTableOfContents from "./MiniTableOfContents"
import Heading from "./Heading"
import PhotoGrid from "./PhotoGrid"
import JobBlocks from "./JobBlocks"
import ProcessSteps from "./ProcessSteps"
import Benefits from "./Benefits"
import FAQs from "./FAQs"

const introTextContainerStyles = theme => ({
  display: `flex`,
  marginTop: theme.space[13],
  flexDirection: `column`,
  aligmItems: `flex-start`,

  [theme.mediaQueries.tablet]: {
    flexDirection: `row`,
    alignItems: `flex-end`,
  },
})

const introTextStyles = {
  ...textStyles,
  ":last-of-type": {
    marginBottom: 0,
  },
}

const processTextStyles = theme => ({
  ...textStyles,
  marginBottom: 0,
  [theme.mediaQueries.tablet]: {
    marginBottom: textStyles.marginBottom,
  },
})

const desktopOnly = theme => ({
  display: `none`,
  [theme.mediaQueries.tablet]: {
    display: `initial`,
  },
})

const aboutStyles = {
  flex: 2,
}
const tableOfContentsStyles = {
  flex: 1,
  marginRight: 60,
}

const NullComponent = () => null

const Careers = ({ location, data }) => {
  const { contentfulPage, openJobs } = data
  const { colors } = getTheme()

  const layout = getLayout(location.pathname)

  const benefitsContentBlock = contentfulPage.contentBlocks.find(
    block => block.__typename === "ContentfulBenefitsBlock"
  )
  const faqContentBlock = contentfulPage.contentBlocks.find(
    block => block.__typename === "ContentfulFaqBlock"
  )

  // WibblyWobblyCircle is a surprisingly heavy module (~16kb gzip), since it
  // uses React Spring and Immer. Gatsby doesn't yet support Suspense, so this
  // is an improvized version of React.lazy.
  // While loading, it doesn't render any sort of fallback, but that's OK.
  const [WibblyWobblyCircle, setWibblyWobblyCircle] = React.useState(
    () => NullComponent
  )
  React.useEffect(() => {
    import("./geometries/WibblyWobblyCircle")
      .then(mod => mod.default)
      .then(Component => setWibblyWobblyCircle(() => Component))
  }, [])

  return (
    <Layout pathname={location.pathname} background={layout.background}>
      <SEO contentfulPage={contentfulPage} url={location.href} />

      <Header />

      <main>
        <SkipNavTarget />
        <HeroContainer>
          <Hero page={contentfulPage} layout={layout} />
        </HeroContainer>

        <Container>
          <RotatingSquare
            css={{ position: "absolute", left: -250, top: 120 }}
          />
          <WibblyWobblyCircle
            color={colors.gatsby}
            css={theme => [
              desktopOnly(theme),
              {
                position: "absolute",
                right: -105,
                top: -245,
              },
            ]}
          />

          <div css={introTextContainerStyles}>
            <div css={aboutStyles}>
              <p css={introTextStyles}>
                <strong>We’re Gatsby!</strong> 👋 Our mission is to make
                building blazing-fast sites fun. We’re an eclectic group who
                loves tinkering with hard problems, collaborating with each
                other, and geeking out about the future of the web. We also
                despise whiteboarding, have an ongoing cat vs dog debate, and
                send each other way too many articles. We seek to be a place for
                everyone, and we sure hope we have the right role for you.
              </p>
              <p css={introTextStyles}>
                If you’re interested in joining our team,
                <br />
                <SamePageLink id="open-positions">
                  check out our open positions
                </SamePageLink>
              </p>
            </div>
            <MiniTableOfContents css={tableOfContentsStyles}>
              <MiniTableOfContents.Item id="who-we-are">
                Who we are
              </MiniTableOfContents.Item>
              <MiniTableOfContents.Item id="open-positions">
                Open Positions
              </MiniTableOfContents.Item>
              <MiniTableOfContents.Item id="process">
                Process
              </MiniTableOfContents.Item>
              <MiniTableOfContents.Item id="benefits">
                Benefits
              </MiniTableOfContents.Item>
              <MiniTableOfContents.Item id="faqs">
                FAQs
              </MiniTableOfContents.Item>
            </MiniTableOfContents>
          </div>
        </Container>

        <Container>
          <Heading id="who-we-are">Who we are</Heading>
          <p css={textStyles}>
            We’re looking for extraordinary people. Like you. In order to shape
            the future of the web, we need a workforce that represents and
            elevates the voices of the users we serve. Help us foster a culture
            where differences are valued.
          </p>

          <PhotoGrid />

          <WibblyWobblyCircle
            color={colors.teal[70]}
            size={220}
            css={theme => [
              desktopOnly(theme),
              {
                position: "absolute",
                left: 180,
                bottom: 150,
              },
            ]}
          />
          <RotatingSquare
            size={130}
            css={theme => [
              desktopOnly(theme),
              { position: "absolute", left: 875, top: 430 },
            ]}
            color={colors.orange[70]}
          />
          <Circle
            color={colors.gatsby}
            size={110}
            css={theme => [
              desktopOnly(theme),
              { position: "absolute", left: 900, top: 1075 },
            ]}
          />
        </Container>

        <Container>
          <Heading id="open-positions">Open Positions</Heading>
          <JobBlocks jobs={openJobs.nodes} />
        </Container>

        <Container>
          <Heading id="process">Process</Heading>
          <p css={processTextStyles}>
            Getting a new job can feel amazing, but it&apos;s often a
            particularly unpleasant experience. That&apos;s why our team has
            worked together to create a meaningful hiring process — that
            doesn&apos;t suck. Learn about the five steps you can expect below!
          </p>

          <ProcessSteps />

          <Circle
            color={colors.teal[40]}
            size={110}
            css={{ position: "absolute", left: -120, top: 100 }}
          />
        </Container>

        {benefitsContentBlock?.benefits && (
          <Container>
            <Heading id="benefits">Benefits</Heading>

            <Benefits benefits={benefitsContentBlock.benefits} />
          </Container>
        )}

        {faqContentBlock?.questions && (
          <Container>
            <Heading id="faqs">FAQs</Heading>

            <FAQs questions={faqContentBlock.questions} />
          </Container>
        )}
      </main>
      <Footer />
    </Layout>
  )
}

Careers.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default Careers

export const pageQuery = graphql`
  query ContentfulCareersPage {
    openJobs: allGreenhouseJob(sort: { fields: updated_at, order: DESC }) {
      nodes {
        id
        title
        content
        fields {
          slug
        }
      }
    }
    contentfulPage(contentfulid: { eq: "careers" }) {
      name
      title
      slug
      parentPage {
        name
        title
        slug
      }
      contentBlocks {
        __typename
        ... on ContentfulJobsBlock {
          id
          jobBlocks {
            id
            jobTitle
            jobDescription {
              jobDescription
            }
            jobPage {
              slug
            }
          }
        }
        ... on ContentfulBenefitsBlock {
          id
          benefits {
            id
            name
            icon {
              file {
                url
              }
            }
            description {
              description
              childMarkdownRemark {
                html
              }
            }
          }
        }
        ... on ContentfulFaqBlock {
          id
          questions {
            id
            question
            answer {
              answer
            }
          }
        }
      }
    }
  }
`
